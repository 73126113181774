import * as React from "react"
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Articles from "../components/articles"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import useIntersection from '../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.15 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};

const ArticleSEO = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'article-page whitemain'
    }}
/>
  <Layout pageTitle="ArticleSEO">
    <section id="main">
      <div id="article-intro">
        <div className="left-col">
          <h1><span className="revealblue">How to improve in search ranking?</span></h1>
          <p><span className="revealblack">DEV INSIGHTS | 17 APR 2016</span></p>
        </div>
        <div className="right-col picture">
            <img src={'/img/single/single-news-3.jpg'} width="840" alt="SEO" />
        </div>
      </div>
    </section>

    <Section id="article-content">
      <div id="inner-content">

        <h2>Before working on improving your website’s search engines ranking you need to understand what your visitors want, deliver the right content and use the right target keywords.</h2>
        <p><strong>1. SEO Strategy</strong></p>
        <p>It’s always about increasing your website’s visibility for sales and/or visitors who become your customers, that is where you have to work on your marketing along with your <a href="https://tobstar.com.au/capabilities/digital-strategy/">website strategy</a> and content which are optimised for search engines.</p>
        <p>Understand what your customer wants and what keyword phrases they use to search on the Internet and what is your goal. Such as, what do you hope to achieve from your potential customers? Like: buy your products, visit your store, get more inquiries or share information. Work on your content planning to achieve your goals.</p>
        <p><strong>2. Keyword Planning</strong></p>
        <p>Next step is your keyword planning, identifying the keywords that are relevant to your business and website, and what the real people will be typing into Google. You can use <a href="https://adwords.google.com/KeywordPlanner" target="_blank" rel="noopener noreferrer">Google’s Keyword Planner tool</a> to help you with keyword research to get your search volume data. It’s built for AdWords (this is free and open to anyone) so you will see references, this tool is a great starting point for your keyword research. Also you can try Google Trends to find what are popular keywords and keyword phrases people use today.</p>
        <p><strong>3. Content</strong></p>
        <p>Once you start writing the contents, use your optimal target keywords with a page title (H1), sub heading (H2), first paragraph of the content. Inform your developer what is your target keywords for each page so they can be optimised, for example, to optimise each page’s images and URL also include the keyword phrase, if you have WordPress (or other CMS) then it’s easy to add yourself.</p>
        <p><strong>4. Think Locally</strong></p>
        <p>Don’t forgot to think locally! Is your business based in Melbourne or another city, real people type location into Google to find local products or services. Use your capital City in your service area then add your suburb along with it. Don’t forgot <a href="https://www.google.com/business/" target="_blank" rel="noopener noreferrer">Google Business Listing</a>, that will help too.</p>
        <p><strong>5. Site Speed, Optimised and Mobile Friendly</strong></p>
        <p>Couple of important things to improve ranking is your site speed and a website optimised for fast loads. And ensure your website is mobile friendly as there’s a high percentage of people surfing the Internet with their smart phones and tablets.</p>
        <p>Reference: <a href="https://gtmetrix.com" target="_blank" rel="noopener noreferrer">https://gtmetrix.com</a>&nbsp;and&nbsp;<a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noopener noreferrer">https://developers.google.com/speed/pagespeed/insights/</a></p>
        <p><strong>6. Monitoring Search Traffic</strong></p>
        <p>Keep monitoring your search traffic using <a href="https://www.google.com/webmasters/" target="_blank" rel="noopener noreferrer">Google’s Search Console</a> to check where your website is ranking. This uses search analytics to get search traffic data with the inquiries your keywords produced and found your website, and how it’s ranking if there is any impressions e.g. show up on the search page and how many clicks. This helps identify which keywords you should use.</p>
        <p>Reference: <a href="http://www.seomark.co.uk/website-ranking-google/" target="_blank" rel="noopener noreferrer">http://www.seomark.co.uk/website-ranking-google/</a></p>
        <p><img src={'/img/articles/search-traffic.png'} alt="Search Analytics" width="921" height="722" /></p>
        <p><strong>7. Quality Backlinks</strong></p>
        <p>So to improve your search engine ranking, use the right keywords phases, optimise website for fast speed loads, optimise the keywords phase on your content pages, and last is build natural and relevant backlinks to your website. Don’t try spamming your links, or it may be penalised by Google.</p>
        <p>Reference: <a href="http://www.seomark.co.uk/high-quality-backlinks/" target="_blank" rel="noopener noreferrer">http://www.seomark.co.uk/high-quality-backlinks/</a></p>
        <p><strong>8. Patience</strong></p>
        <p>Be patient, you have set your goals, identified your keywords and written your unique contents that are targeted to your customers. Google will pick up your relevant contents for your targeted potential customers. Keep monitoring your search traffic, update your contents, blogs, articles and create new pages and within time you should see movement in your search ranking and increased visitors from Google.</p>
        <p>We provide SEO report and recommendations with use of SEO spider tool from <a href="http://www.screamingfrog.co.uk/seo-spider/" target="_blank" rel="noopener noreferrer">Screaming Frog</a> to find any SEO issues. Set up of <a href="https://www.google.com/analytics" target="_blank" rel="noopener noreferrer">Google Analytics</a>, Google’s Webmaster Tool, Bing’s Webmaster Tool, create XML sitemap index file and submit to Google and Bing. Read more about our <AniLink cover to="/capabilities/search-engines-optimisation/" bg="#00ccd4" direction="right" duration={0.76}>SEO capability</AniLink>.</p>

      </div>
    </Section>
<Articles></Articles>
</Layout>

  </>
  )
}

export default ArticleSEO


export const Head = () => (
  <>
    <title>How to improve in search ranking? - Tobstar</title>
    <meta name="description" content="Before improving your website’s search engines ranking you need to understand what your visitors want, deliver the right content and right target keywords." />
  </>
)